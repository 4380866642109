/* width */
.scroll_webkit::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scroll_webkit::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #073332;
  border-radius: 10px;
}

/* Handle */
.scroll_webkit::-webkit-scrollbar-thumb {
  background: #073332;
  border-radius: 10px;
}

/* Handle on hover */
.scroll_webkit::-webkit-scrollbar-thumb:hover {
  background: #073332;
}
.border-bottom {
  border-bottom: 2px solid #073332;
  padding-bottom: 17px;
  width: 212px;
}
.news_border {
  border-left: 2px solid #f08a23;
}

.headingofAaas {
  color: #2f7f3d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 48px */
}

.textofAboutUs {
  color: #073332;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.textoftheHirreachy {
  color: #2c2929;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.accordian-style-leadeship {
  border-radius: 8px 8px 0px 0px;
  background: rgba(7, 51, 50, 0.75);
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.accordian-style-font {
  align-self: stretch;
  color: #fff;

  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* //text-transform: capitalize; */
}

.accordian-style-text {
  color: #323232;

  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.accordian-style-bold {
  color: #323232;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.absolute22 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.rotating-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; Adjust as needed */
}

.rotating-image {
  animation: rotate 100s linear infinite;
  max-width: 100%;
  max-height: 100%;
}

.paused {
  animation-play-state: paused;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bgPictureLeadershipOrg {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/bgPictureLeadershipOrg.png");
}

.bgPictureLeadershipOrgLeadership {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/bggggg.png");
}
.bgPictureOurTeam {
  background-repeat: no-repeat;
  background-image: url("../../assests/images/bgTeam.png");
}
.headingTeam {
  color: #073332;
  font-family: "Crimson-Text";
  font-size: 28px;
  font-weight: 700;
  /* text-align: center; */
  line-height: 35px;
  padding-bottom: 16px;
}
.pTeam {
  /* text-align: center; */
  color: #073332;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  /* //text-transform: capitalize; */
}
.greenb {
  background-color: #073332;
  /* height: 200px; */
}
@media screen and (max-width: 768px) {
  .greenb {
    background-color: #073332;
    /* height: 100px; */
  }
}
.whiteb {
  background-color: white;
  /* height: 200px; */
}
@media screen and (max-width: 768px) {
  .whiteb {
    background-color: white;
    /* height: 100px; */
  }
}
