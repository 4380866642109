* {
  margin: 0;
  padding: 0;
}
html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  /* background-color: #b5fac7; */
}
/* 
.pages {
  color: #316685;
  text-align: center;
  font-size: 3.5rem;
  /* margin-top: 10%; */
/* width: 100%; */
/* overflow-x: hidden; */
/* }  */
.dropdown.dropdown-navbar-main:hover .dropdown-menu {
  display: block;
}
.bg-gradient {
  background: linear-gradient(180deg, #07333200 0%, #073332 100%);
  /* position: relative; */
  margin-top: -200px;
  height: 300px;
  width: 100%;
}

.font-family-custom {
  font-family: "Crimson-Text";
}
.font-family-p {
  font-family: "Poppins";
}
