.AgricultureMainHeading {
  color: #f08a23;
  text-align: center;

  font-family: "Crimson-Text";
  font-size: 68px;
  font-style: normal;
  font-weight: 700;
  line-height: 90px; /* 132.353% */
}
.slick-slide {
}
.AgricultureMainText {
  color: #2c2929;
  text-align: center;

  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.HeadingPage {
  color: #2f7f3d;
  text-align: center;

  font-family: "Crimson-Text";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 90px; /* 187.5% */
}

.Potentialbg-image1 {
  background-image: url("../../assests/images/Frame\ 7659.png");
}
.Potentialbg-image2 {
  background-image: url("../../assests/images/Frame\ 7668.png");
}
.Potentialbg-image3 {
  background-image: url("../../assests/images/Frame\ 7671.png");
}

.HeadingHover {
  color: #fff;

  font-family: "Crimson-Text";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agriculture-box-text {
  color: #fff;

  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* //text-transform: capitalize; */
  text-align: center;
}

.numbersBox {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 26px; */
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.numbersBoxWhy {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 26px; */
  border: 2px solid #f08a23;
}

.numbersBoxindustry {
  display: flex;
  height: 250px;
  width: 275px;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.numbersBox22 {
  display: flex;
  height: 250px;
  width: 295px;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.headingNumbers {
  color: #f08a23;

  font-family: "Crimson-Text";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 83.333% */
}
.headingNumberText {
  color: #fff;
  text-align: center;

  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bg-numbers {
  background-image: url("../../assests/images/agriGlobalrankingBG.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-numbersinfoT {
  background-image: url("../../assests/images/Frame\ 7730.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-numbersIT {
  background-image: url("../../assests/images/ITPotential.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-numbersMines {
  background-image: url("../../assests/images/minesPotentiol.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-numbersIndustry {
  background-image: url("../../assests/images/industryPotentiol.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sliderProjectsListHover {
  color: #f08a23;
  text-align: left;

  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 90px; 450% */
  border-radius: 100px 0px 0px 100px;
  background: #fafafa;
}

.sliderProjectsListHover {
  color: #f08a23;
  text-align: left;

  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 90px; 450% */
  border-radius: 100px 0px 0px 100px;
  background: #fafafa;
}

.sliderProjectsListHoverInvestment {
  color: #f08a23;
  text-align: left;

  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 90px; 450% */
  border-radius: 100px 0px 0px 100px;
  background: #fafafa;
}

.sliderProjectsListInvestment {
  color: #3232327d;
  text-align: left;

  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  /* font-weight: 600; */
  /* line-height: 90px; 450% */
}

.sliderProjectsList {
  color: #3232327d;
  text-align: left;

  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  /* font-weight: 600; */
  /* line-height: 90px; 450% */
}

.HeadingProjectSector {
  color: #22574d;

  font-family: "Crimson-Text";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  /* border-radius: 100px 0px 0px 100px;
  background: #fafafa; */
  /* line-height: 90px; 250% */
  /* letter-spacing: -0.72px; */
}
.TextProjectSector {
  color: #323232;

  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.description-projectHover {
  color: #2f7f3d;

  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 450% */
  text-decoration: underline;
  text-decoration-color: #f08a23;
  text-underline-offset: 7px;
  /* text-underline-width: 12px; */
}

.description-project {
  color: #6f7575;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 500% */
  text-decoration: underline;
  text-decoration-color: #6f7575;
  text-underline-offset: 7px;
  /* text-underline-width: 12px; */
}

.headingaeraeText {
  color: #323232;

  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headingaeraeTextText {
  color: #6f7575;

  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mail {
  color: #073332;

  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.desginame {
  color: #073332;

  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.facilation {
  color: #2f7f3d;
  text-align: center;

  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.howtoInvest {
  color: #2f7f3d;
  text-align: center;

  font-family: "Crimson-Text";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.howtoinvesttext {
  color: #073332;

  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.mapHeading {
  color: #073332;
  font-family: "Crimson-Text";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 100% */
}

.dropdownheading {
  color: #2f7f3d;

  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;

  font-weight: 600;

  line-height: normal;
  /* //text-transform: capitalize; */
}

.dropdownText {
  color: #323232;

  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.helpdeskButton {
  display: flex;
  padding: 16px 32px;
  align-items: center;
  gap: 12px;
  background-color: #329244;
  color: #fff;

  font-family: "Inter-Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 41.92px; /* 299.429% */
}

.textButtonhelp {
  color: #fff;

  font-family: "Inter-Light";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 41.92px; /* 299.429% */
}

.GlobalRankingHeading {
  color: #f08a23;

  font-family: "Crimson-Text";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px; /* 83.333% */
}

.RisingDemands {
  color: #329244;

  font-family: "Crimson-Text";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 100% */
}

.RisisingDemandsSubtect {
  color: #073332;

  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.risingDemandText {
  color: #073332;

  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.EnergySectorBoxes {
  display: flex;
  width: 260px;
  padding: 20px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  border: 1px solid rgba(5, 104, 57, 0.35);
  background: #fff;
}

.EnergySectorBoxesHeading {
  color: #000;

  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  /* line-height: normal; */
}
.EnergySectorBoxesTagline {
  color: #000;

  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  /* line-height: normal; */
}

.EnergySectorBoxesText {
  color: #000;

  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
}

.SetEnergySector {
  color: #2c2929;

  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.button11active {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #83cec1;
  background: rgba(131, 206, 193, 0.15);
  color: #369e47;
  text-align: center;

  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 200% */
}

.button11 {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid rgba(131, 206, 193, 0.15);
  color: #073332;
  text-align: center;

  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 200% */
}

.mapsNumbers {
  color: #073332;
  text-align: center;

  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Mapstype {
  color: #073332;
  text-align: center;

  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
}
.mapsTexts {
  color: #073332;
  text-align: center;

  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 225% */
}

.HeadingDonutChart {
  color: #369e47;
  text-align: center;

  font-family: "Crimson-Text";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 90px; /* 187.5% */
}

.dull {
  opacity: 0.5; /* Adjust the opacity value as needed */
  transition: opacity 0.5s ease; /* Add transition for a smooth effect */
}

.custom-scrollbar {
  max-height: 200px; /* Set your preferred max-height for the content */
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #2f7f3d #f1f1f1; /* Adjust colors as needed */

  /* Webkit (Safari/Chrome) */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2f7f3d; /* Adjust thumb color */
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Adjust track color */
  }
}
/* Add these styles to your Tailwind CSS or custom stylesheet */

/* For Webkit (Safari/Chrome) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #2f7f3d; /* Adjust thumb color */
  border-radius: 20px; /* Add rounded corners */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Adjust track color */
}

/* For Firefox */
.scrollbar-thin {
  scrollbar-color: #2f7f3d #f1f1f1; /* Adjust colors */
}

.scrollbar-thin::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #2f7f3d; /* Adjust thumb color */
  border-radius: 4px; /* Add rounded corners */
}

.scrollbar-thin::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Adjust track color */
}
