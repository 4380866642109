@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./assests/Fonts/Poppins/Poppins-Light.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./assests/Fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url("./assests/Fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./assests/Fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Crimson-Text";
  src: local("Crimson-Text"),
    url("./assests/Fonts/Crimson_Text/CrimsonText-Bold.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
    url("./assests/Fonts/inter/Inter-Regular.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Inter-Light";
  src: local("Inter-Light"),
    url("./assests/Fonts/inter/Inter-Light.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Inter-SemiBold";
  src: local("Inter-SemiBold"),
    url("./assests/Fonts/inter/Inter-SemiBold.ttf") format("truetype");
  /* font-weight: bold; */
}

.Crimson-Text {
  font-family: Crimson-Text;
}

.Poppins {
  font-family: "Poppins-Regular";
}
body {
  margin: 0;
  /* font-family: "Poppins"; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

.paragraph {
  color: #323232;
  text-align: center;

  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.Heading {
  color: #22574d;
  /* leading-trim: both;
text-edge: cap; */
  font-family: Crimson Text;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 166.667% */
  /* //text-transform: capitalize; */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.contianer-width {
  max-width: 1500px;
  width: 100%;
}

.btn {
  text-decoration: none;
  color: #161616;
  text-transform: uppercase;
  position: relative;
  /* margin-left: 2em; */
  padding: 5px 23px 5px 10px;
  transition: all 0.6s ease;
  color: #329244;
  text-align: center;

  font-family: "Inter-Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 100% */
}

.btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  right: -3em;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
  border: 1px solid #f08a23;
  width: 2.6em;
  height: 2.6em;
  border-radius: 5em;
  transition: all 0.6s cubic-bezier(0.615, 0, 0.07, 1);
}

.btn:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='3' viewBox='0 0 20 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3625 1.61726C12.9948 1.24729 12.9948 0.647443 13.3625 0.277476C13.7301 -0.0924918 14.3262 -0.0924918 14.6939 0.277476L19.7153 5.33016C20.083 5.70014 20.083 6.29989 19.7153 6.66987L14.6939 11.7225C14.3262 12.0925 13.7301 12.0925 13.3625 11.7225C12.9948 11.3525 12.9948 10.7528 13.3625 10.3828L16.7765 6.94738L0.937607 6.94739C0.417628 6.94739 -0.00390625 6.52322 -0.00390625 6.00001C-0.00390625 5.47681 0.417628 5.05264 0.937607 5.05264L16.7765 5.05264L13.3625 1.61726Z' fill='%23329244'/%3E%3C/svg%3E");
  color: transparent; /* Hide text content */
  font-size: 2.6em;
  position: absolute;
  z-index: -1;
  right: -0.8em;
  top: 0px;
  bottom: 0;
  margin: auto;
  width: 1.1em;
  height: 1.1em;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.btn:hover {
  transition: all 0.6s ease;
  transition-delay: 0.3s;
  color: #fff;
  /* background-color: #f08a23; */
}

.btn:hover:before {
  transition: all 0.6s cubic-bezier(0.615, 0, 0.07, 1);
  width: 160%;
  background-color: #f08a23;
}

/* cccccccccccccccccccccccccccccccc */

.btn1 {
  text-decoration: none;
  color: #161616;
  /* text-transform: uppercase; */
  position: relative;
  /* margin-left: 2em; */
  padding: 5px 23px 5px 10px;
  transition: all 0.6s ease;
  color: #329244;
  text-align: center;

  font-family: "Inter-Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 100% */
}

.btn1:before {
  content: "";
  position: absolute;
  z-index: -1;
  right: -3em;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
  border: 1px solid #f08a23;
  width: 2.6em;
  height: 2.6em;
  border-radius: 5em;
  transition: all 0.6s cubic-bezier(0.615, 0, 0.07, 1);
}

.btn1:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='3' viewBox='0 0 20 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3625 1.61726C12.9948 1.24729 12.9948 0.647443 13.3625 0.277476C13.7301 -0.0924918 14.3262 -0.0924918 14.6939 0.277476L19.7153 5.33016C20.083 5.70014 20.083 6.29989 19.7153 6.66987L14.6939 11.7225C14.3262 12.0925 13.7301 12.0925 13.3625 11.7225C12.9948 11.3525 12.9948 10.7528 13.3625 10.3828L16.7765 6.94738L0.937607 6.94739C0.417628 6.94739 -0.00390625 6.52322 -0.00390625 6.00001C-0.00390625 5.47681 0.417628 5.05264 0.937607 5.05264L16.7765 5.05264L13.3625 1.61726Z' fill='%23329244'/%3E%3C/svg%3E");
  color: transparent; /* Hide text content */
  font-size: 2.6em;
  position: absolute;
  z-index: -1;
  right: -0.8em;
  top: 0px;
  bottom: 0;
  margin: auto;
  width: 1.1em;
  height: 1.1em;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.btn1:hover {
  transition: all 0.6s ease;
  transition-delay: 0.3s;
  color: #fff;
  /* background-color: #f08a23; */
}

.btn1:hover:before {
  transition: all 0.6s cubic-bezier(0.615, 0, 0.07, 1);
  width: 130%;
  background-color: #f08a23;
}
