/* import "./" */

.bgPictureABoutUs {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/AboutUSBG.jpg");
}

.bgPictureAgri {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/SectorsbannerBG/Agri.jpg");
}
.bgPictureEnergy {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/SectorsbannerBG/energy.jpg");
}
.bgPictureIndustry {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/Rectangle\ 3465.jpg");
}
.bgPicturemines {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/SectorsbannerBG/mines.jpg");
}
.bgPictureNewsandEvents {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/Newsandeventsbg.jpg");
}
.bgFAQ {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/FAQ's.jpg");
}
.contactusBG {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/Contact\ us\ N.jpg");
}
.bg-vision {
  background-image: url("../../assests/images/bg-vision.png") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.aboutusHeading {
  color: #fff;
  font-family: "Poppins";
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sub_nav_bg {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 9999;
  color: black;
  /* -webkit-backdrop-filter: blur(24.699999809265137px); */
  backdrop-filter: blur(50px);
}

.imagesetting {
  width: 360px;
  height: 383px;
}

.textBoxforaboutus {
  display: flex;
  width: 750px;
  height: 496px;
  padding: 0px 30px 30px 30px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 28px;
  border-radius: 8px;
  border: 1px solid #329244;
}

.leaderName {
  color: #073332;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.leaderdesig {
  color: #073332;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.leadertext {
  color: #073332;
  text-align: justify;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: jus;
}

.missionbox {
  display: flex;
  width: 500px;
  padding: 40px 16px;
  flex-direction: column;
  align-items: center;
  gap: 22px;
  border-radius: 8px;
  border: 1px solid #83cec1;
  height: 350px;
}
.missionBoxHeading {
  color: #fff;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.missionBoxText {
  color: #fff;
  text-align: center;
  leading-trim: both;
  padding: 0x 20px;
  text-align: justify;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.mandareBox {
  display: flex;
  padding: 50px;
  flex-direction: column;
  /* align-items: center; */
  /* gap: 32px; */
  border-radius: 8px;
  border: 1px solid #348b7c;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 25px 0px rgba(131, 206, 193, 0.15);
}

.mandateHeading {
  color: #073332;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.mandateText {
  color: #073332;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: left;
}

.whySIFC {
  color: #2f7f3d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.whySIFCText {
  text-align: left;
  color: #323232;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.whySIFC ul {
  list-style-type: circle;
  list-style: circle !important;
}

.Heading22 {
  color: #323232;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.forPakistan {
  color: #056839;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bgPictureContactus {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/ContactusBG.png");
}
.PartnersBG {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/Rectangle\ 3457\(4\).png");
}

.inputtextcontact {
  display: flex;
  /* width: 360px; */
  /* padding: 17px 12px; */
  align-items: center;
  /* gap: 10px; */
  /* flex-shrink: 0; */
  align-self: stretch;
  border-bottom: 1px solid #2f7f3d;
  background: #ebebeb;
  font-size: 16px;
}
.react-international-phone-input-container .react-international-phone-input {
  width: 100%;
}
.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  background-color: #ebebeb !important;
}
.react-international-phone-input-container .react-international-phone-input {
  background-color: #ebebeb !important;
}
/* .phonenumber
  .react-international-phone-input-container
  .react-international-phone-input {
  overflow: visible;
  width: 100%;
  /* width: 270px; */
/*height: var(--react-international-phone-height, 36px);
  box-sizing: border-box;
  padding: 0 8px;
  border: 1px solid var(--react-international-phone-border-color, gainsboro);
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin: 0;
  background-color: #ebebeb !important;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: var(--react-international-phone-text-color, #222);
  font-family: inherit;
  font-size: var(--react-international-phone-font-size, 13px);
}
.phonenumber
  .react-international-phone-input-container
  .react-international-phone-country-selector-button {
  width: 100%;
  /* width: 98px; 
  height: 60px !important;
  border-style: solid !important;
  border-color: #2f7f3d !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 1px solid #2f7f3d !important;
  border-bottom: 1px solid #2f7f3d !important;
  background-color: #ebebeb !important;
} */

.contactusInforheading {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.contactusInforText {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  /* line-height: 69.531px; 347.656% */
}

.headingFindUs {
  color: #26532f;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.textFindUs {
  color: #323232;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.heading4 {
  color: #073332;
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hoverstateOfMissionandVisionm:hover {
  border-radius: 8px;
  border: 1px solid #83cec1;
  background: rgba(131, 206, 193, 0.15);
  box-shadow: 0px 11px 27.2px 0px rgba(131, 206, 193, 0.15);
}
