/* import "./" */
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap");
.Success-h-Font {
  font-family: "Crimson Text", serif;
}

.success-g-font {
  font-family: "Inter", sans-serif;
}

.success-b-font {
  font-family: "Poppins", sans-serif;
}

.contianer-width {
  max-width: 1500px;
  width: 100%;
}

.bgPictureSucessStories {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/successStoriesBG.png");
}

.newsbg {
  background-image: url("../../assests/images/SuccessNews.png");
}

.herotagline {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  /* font-size: 36px; */
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 122.222% */
}

.imagesetting {
  width: 360px;
  height: 383px;
}

.textBoxforaboutus {
  display: flex;
  width: 750px;
  height: 496px;
  padding: 0px 30px 30px 30px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 28px;
  border-radius: 8px;
  border: 1px solid #329244;
}

.leaderName {
  color: #073332;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.leaderdesig {
  color: #073332;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.leadertext {
  color: #073332;
  text-align: justify;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: jus;
}

.bg-vision {
  background-image: url("../../assests/images/Rectangle\ 3472\(1\).png");
  background-position: center;
  background-repeat: no-repeat;
}
/* 
.missionbox {
  display: flex;
  width: 750px;
  padding: 40px 16px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid #83cec1;
  height: 295px;
} */
.missionBoxHeading {
  color: #fff;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* .missionBoxText {
  color: #fff;
  text-align: center;
  leading-trim: both;
  padding: 0x 25px;
  text-align: justify;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
} */

.mandareBox {
  display: flex;
  padding: 50px;
  flex-direction: column;
  /* align-items: center; */
  /* gap: 32px; */
  border-radius: 8px;
  border: 1px solid #348b7c;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 25px 0px rgba(131, 206, 193, 0.15);
}

.mandateHeading {
  color: #073332;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.mandateText {
  color: #073332;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: left;
}

.whySIFC {
  color: #2f7f3d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.whySIFCText {
  text-align: left;
  color: #323232;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.whySIFC ul {
  list-style-type: circle;
  list-style: circle !important;
}

.Heading22 {
  color: #323232;
  text-align: center;

  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.forPakistan {
  color: #056839;

  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.bgPicturePartners {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/Rectangle\ 3457\(5\).png");
}

.inputtextcontact {
  display: flex;
  width: 360px;
  padding: 17px 12px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  border-bottom: 1px solid #2f7f3d;
  background: #ebebeb;
  font-size: 16px;
}

.textFindUs {
  color: #323232;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
