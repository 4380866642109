.progress-container {
  width: 100%;
  height: 3px;
  background-color: #ccc; /* Light grey background */
  position: relative;
}

.progress-bar {
  height: 100%;
  width: 20%; /* 20% dark green */
  background-color: #26532f; /* Dark green color */
}

.headingPreviousEvents {
  color: #26532f;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.accordion-item {
  border-radius: 8px;
  border: 1px solid rgba(5, 104, 57, 0.28);
  padding: 24px;
  width: 100%;
  max-width: 1100px;
}

.progress-containerSucessStories {
  width: 55%;
  height: 3px;
  background-color: #ccc; /* Light grey background */
  position: relative;
}

.progress-barSucessStories {
  height: 100%;
  width: 30%; /* 20% dark green */
  background-color: #26532f; /* Dark green color */
}

.textHeafingMandate {
  color: #2f7f3d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 43px; /* 179.167% */
  /* //text-transform: capitalize; */
}

@media only screen and (max-width: 767px) {
  .textHeafingMandate {
    font-size: 18px;
    line-height: 22px;
  }
}

.textMandate {
  color: #323232;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@media only screen and (max-width: 767px) {
  .textMandate {
    font-size: 12px;
  }
}

.textHeafingMandateNonActive {
  color: #cbdfcf;

  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 43px; /* 179.167% */
}
.textMandateNonActive {
  color: #ececec;

  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.NumberMandateTextx {
  color: #2f7f3d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* Add these styles to your CSS */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 1000;
}
