.bgPictureInvestmentClimate {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/core\ values.jpg");
}

.bgPictureInvestmentClimate11 {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/Investment\ Climate\ N\ copy.jpg");
  background-size: cover;
}

.bgPictureInvestmentClimate22 {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/bgINvestmentCLimate.png");
}
.missionBoxHeading {
  color: #83cec1;
  text-align: center;

  font-family: "Crimson-Text";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.leaderdesig {
  color: #fff;
  text-align: center;

  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.circle {
  position: relative;
  /* Add any common styles for the circles here */
}

.circle::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 100%;
  height: 1px;
  background: #fff; /* Add the color for the dashed border */
  border-radius: 1px;
  z-index: -1;
}
