.contianer-width {
  max-width: 2150px;
  width: 100%;
}

.contianer-width-tender {
}
.container-width-SIFC-faclitates {
  max-width: 1440px;
  width: 100%;
}

.bgPicture {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/One.png");
}

.bgPicture22 {
  background-repeat: no-repeat;
  background-position: center;
}

.heroHeading {
  color: #83cec1;
  text-align: center;
  font-family: "Crimson-Text";
  /* font-size: 96px; */
  font-style: normal;
  font-weight: 700;
  /* line-height: 80px;  */
  /* text-transform: capitalize; */
}

.herotagline {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  /* font-size: 36px; */
  font-style: normal;
  font-weight: 400;
  /* line-height: 44px; */
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee {
  animation: marquee 70s linear infinite;
}

.paused {
  animation-play-state: paused;
}

.flash-highlight {
  border-top: 1px solid rgba(34, 87, 77, 0.31);
  border-bottom: 1px solid rgba(34, 87, 77, 0.31);
  display: flex;
  align-items: center;
  color: #fff;
  /* justify-content: center; */
  font-family: "Poppins";
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 100% */
  background: #073332;
}

.flash-highlight-content {
  border-top: 1px solid rgba(34, 87, 77, 0.31);
  border-bottom: 1px solid rgba(34, 87, 77, 0.31);
  background-color: #f8f8f8;
  color: #073332;
  font-family: "Poppins";
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 100% */
  width: calc(100% - 186px);
  overflow: hidden;
  display: flex;
  align-items: center;
}
/* Whhy Pakistan Section */

.container-width12 {
  border: 1px solid green;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto; /* Center the container */
  position: relative; /* Set the position to relative for absolute positioning within */
  height: 630px;
  overflow: hidden;
}

.content {
  /* Your content styles */
  position: relative; /* Set the position to relative */
  z-index: 2; /* Ensure content is on top of the background */
}

.background-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-image: url("../../assests/images/Vector.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}

.boxes-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; /* Adjust as needed */
}

.box {
  width: 23%; /* Adjust as needed */
  height: 250px; /* Adjust as needed */
  background-color: #ccc; /* Adjust as needed */
}

.headingInvestInPakistan {
  color: #22574d;
  font-family: Crimson Text;
  font-size: 40px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 166.667% */
  /* text-transform: capitalize; */
}

.timings {
  color: #cbcbca;

  font-family: "Poppins-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
}

.EventHeading {
  color: #26532f;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins-SemiBold";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 25px */
}

.heading2 {
  color: #555;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 136.364% */
}

.heading234 {
  color: #555;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}

.container-width-InvestInPakistan {
  max-width: 1440px;
  width: 100%;
  position: relative;
  padding: 60px 40px 0px 40px;
  background-color: #f8f8f8;
  border-radius: 12px;
  border: 1px solid #eeee;
}

.digitalPakistanWhyInvestInPakistan {
  color: #296bce;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.digitalPakistanWhyInvestInPakistanAgriculture {
  color: #2f7f3d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.digitalPakistanWhyInvestInPakistanpower {
  color: #f05423;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.digitalPakistanWhyInvestInPakistantourism {
  color: #00c9d6;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.digitalPakistanWhyInvestInPakistanmineral {
  color: #b4814e;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.DigitalPakistanLearnMore {
  color: #296bce;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 41.92px; 299.429% */
}
.text-DigitalPakistanDP5 {
  color: #000;
  text-align: left;
  font-family: "Crimson-Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px; /* 137.5% */
}

.DP-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #296bce;
  background: #fff;
  cursor: pointer;
}
.DP-box:hover {
  box-shadow: 0px 12px 33.1px 0px rgba(41, 107, 206, 0.2);
}

.AP-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border: 1px solid rgba(47, 127, 61, 0.5);
  background: #fff;
  cursor: pointer;
}
.AP-box:hover {
  box-shadow: 0px 12px 33.1px 0px #d9ffdf;
}

.AgricukturePakistanLearnMore {
  color: #2f7f3d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 41.92px; 299.429% */
}

.Energy-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border: 1px solid rgba(240, 84, 35, 0.5);
  background: #fff;
  cursor: pointer;
}

.Tourism-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border: 1px solid rgba(0, 201, 214, 0.5);
  background: #fff;
  cursor: pointer;
}

.Energy-box:hover {
  box-shadow: 0px 12px 33.1px 0px #ffc7b6;
}

.EnergyPakistanLearnMore {
  color: #f05423;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 41.92px; 299.429% */
}

.TourismPakistanLearnMore {
  color: #00c9d6;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 41.92px; 299.429% */
}

.mineral-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border: 1px solid rgba(180, 129, 78, 0.5);
  background: #fff;
  cursor: pointer;
}
.mineral-box:hover {
  box-shadow: 0px 12px 33.1px 0px #ffefde;
}

.EnergyPakistanLearnMore {
  color: #b4814e;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 41.92px; 299.429% */
}

.container-width-Why-Invest {
  max-width: 1500px;
  width: 100%;
  position: relative;
}

.bg-whyInvest-in-Pakistan {
  background-image: url("../../assests/images/Vector\(2\).png");
  background-position: top;
  background-repeat: no-repeat;
  /* object-fit: contain; */
  background-size: cover;
}

.OurCoreValues {
  background-image: url("../../assests//images/Frame\ 76161.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-investment-1 {
  background-image: url("../../assests/images/Frame\ 7616.png");
  background-position: top;
  background-repeat: no-repeat;
  /* object-fit: contain; */
  background-size: cover;
}

.bg-investment-2 {
  background-image: url("../../assests/images/Frame\ 7620.png");
  background-position: top;
  background-repeat: no-repeat;
  /* object-fit: contain; */
  background-size: cover;
}

.bg-investment-3 {
  background-image: url("../../assests/images/Frame\ 7618.png");
  background-position: top;
  background-repeat: no-repeat;
  /* object-fit: contain; */
  background-size: cover;
}

.WhyInvestBox {
  display: flex !important;
  width: 100%;
  height: 230px;
  padding: 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 1px solid #eeee;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(7.199999809265137px);
  transition: transform 0.3s ease; /* Added transition property */
}
.WhyInvestBoxSecond {
  display: flex !important;
  width: 350px;
  height: 230px;
  padding: 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 1px solid #ffff;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(7.199999809265137px);
  transition: transform 0.3s ease; /* Added transition property */
}
.WhyInvestBoxSecond:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  transform: translate(
    10px,
    -10px
  ); /* Adjust the values based on your preference */
}
.WhyInvestBox:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 05px 20px;
  transform: translate(
    10px,
    -10px
  ); /* Adjust the values based on your preference */
}

.WP_text {
  color: #2c2929;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 40px;  */
}

.container-width-SIFC-faclitates {
  max-width: 1540px;
  width: 100%;
}

/* .bg-image-hover {
  background-image: url("../../assests/images/Frame\ 7543.png");
} */

/* MyComponent.css */

/* Add your custom styles here */

.Sifc-faclitates-box-heading {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Crimson Text;
  /* font-size: 36px; */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* text-transform: capitalize; */
  text-align: left;
}

.Sifc-faclitates-box-text {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* text-transform: capitalize; */
  text-align: left;
}
/* MyComponent.css */

/* Add your custom styles here */

.transition-opacity {
  /* Define your transition properties for opacity */
  transition-property: opacity;
  transition-duration: 0.5s; /* Adjust the duration as needed */
  transition-timing-function: ease-in-out; /* Adjust the timing function as needed */
}

.transition-all {
  /* Define your transition properties for all */
  transition-property: all;
  transition-duration: 0.5s; /* Adjust the duration as needed */
  transition-timing-function: ease-in-out; /* Adjust the timing function as needed */
}

.bg-image2 {
  background-image: url("../../assests/images/Frame\ 7543.png");
}
.bg-image3 {
  background-image: url("../../assests/images/Frame\ 7544.png");
}

.bg-image1 {
  background-image: url("../../assests/images/Frame\ 7335\(2\).png");
}
/* YourComponent.css */

/* Add your custom styles here */
.overlay2 {
  background: linear-gradient(to top, transparent, #073332);
  opacity: 0.8;
  position: absolute;
  inset: 0;
  z-index: 1;
}

.hover-overlay:hover .overlay {
  background: linear-gradient(to top, transparent, #073332);
  opacity: 0.8;
}
.hover-overlay:hover .overlay.active {
  transform: translateY(0); /* Move to the top when active */
}

.hover-overlay:hover .content-initial {
  opacity: 0;
}

.overlay {
  position: absolute;
  inset: 0;
  background-color: transparent;
  opacity: 0;
  transition: background-color 1s ease-in-out, opacity 1s ease-in-out;
  z-index: 1; /* Set a higher z-index for the overlay */
}
.hover-overlay:hover .overlay.active {
  opacity: 0.6;
}

.content-hover {
  z-index: 9; /* Set a higher z-index for hover content */
}

.content-initial {
  z-index: 1;
}

/* CarouselComponent.css */
.carousel-container {
  width: 100%;
  overflow: hidden;
  margin: auto;
  height: 700px; /* Set the desired height */
  position: relative; /* Add relative positioning */
  padding-bottom: 100px;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s, opacity 0.5s;
  position: relative; /* Add relative positioning */
}

.slick-center .carousel-item {
  transform: scale(2); /* Set center image to 2x */
  width: 70%; /* Adjust the width of the center image */
  height: 100%; /* Set the height to 100% */
  opacity: 1;
  z-index: 1; /* Ensure the center image is on top */
}

/* Overlay for left and right items */
.carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(7, 51, 50, 0), rgba(7, 51, 50, 1));
  opacity: 0.7;
  z-index: 0; /* Place the overlay behind the image */
}

/* Styles for left and right items */
.slick-prev,
.slick-next {
  font-size: 24px;
  color: #fff; /* Set arrow color to white */
  border: none;
  border-radius: 3px;
  padding: 10px;
  z-index: 2; /* Ensure the arrows are on top */
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 1; /* Increase opacity on hover */
}

.slick-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.slick-dots li {
  margin: 0 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #aaa;
  cursor: pointer;
}

.slick-dots li.slick-active {
  background-color: #333;
}

.testnemonials {
  background-image: url("../../assests/images/Group\ 7500.png");
  background-position: center;
  background-repeat: none;
  background-size: cover;
}

.headingTestonimial {
  color: #22574d;

  font-family: "Crimson-Text";
  /* font-size: 48px; */
  font-style: normal;
  font-weight: 700;
  line-height: 80px; /* 166.667% */
  text-transform: capitalize;
}

.testnomianlText {
  color: #323232;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.personNameTestnimial {
  color: #22574d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
}
.Designation {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}

.bg-chief {
  background-image: url("../../assests/images/Vector\(3\).png");
  background-position: left;
  background-repeat: no-repeat;
}

.boxleadership {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  /* background: #fcfcfc; */
}
.boxleadership1 {
  display: flex;
  height: 368px;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  background: #22574d;
  box-shadow: 0px 26px 50px 0px rgba(34, 87, 77, 0.15);
}
.leadershipname {
  color: #22574d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 257.143% */
}
.leadershipname1 {
  color: #ffffff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 257.143% */
}
.leadershipDesignation {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}
.leadershipDesignation1 {
  color: #ffffff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 150% */
}

.leadershipDetails {
  color: #323232;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Light";
  /* font-size: 20px; */
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  /* text-align: left; */
}
.leadershipDetails1 {
  color: #ffffff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Light";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: left;
}

.AllEvents {
  display: flex;
  /* padding: 16px 32px; */
  align-items: center;
  gap: 12px;
  background-color: #329244;
}

.EventsLearnMore {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 41.92px; /* 299.429% */
  /* line-height: 41.92px; 299.429% */
}

.eventsBox {
  display: flex;
  width: 521px;
  height: 312px;
  padding: 28px 30px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
}

.bg-newsandUpdates {
  background-image: url("../../assests/images/Frame\ 7361.png");
  background-position: top;
  background-repeat: no-repeat;
}

.NewsTitle123 {
  color: #26532f;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-SemiBold";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 25px */
  text-align: left;
}
.newsDate {
  color: #073332;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 125%; /* 20px */
  text-align: left;
}

.AreYouready {
  color: #26532f;
  font-family: "Crimson-Text";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 44.8px;  */
}

.inputtext {
  color: #999;
  font-family: "Inter-Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-bottom: 1px solid #2f7f3d;
  background: #fff;
}

.btr {
  border-bottom: 1px solid #2f7f3d;
  background: #fff;
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  width: 98px;
  height: 60px !important;
  border-style: solid !important;
  border-color: #2f7f3d !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 1px solid #2f7f3d !important;
  border-bottom: 1px solid #2f7f3d !important;
  background: #fff !important;
}

.react-international-phone-input-container .react-international-phone-input {
  overflow: visible;
  height: var(--react-international-phone-height, 36px);
  box-sizing: border-box;
  padding: 0 8px;
  border: 1px solid var(--react-international-phone-border-color, gainsboro);
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin: 0;
  background-color: var(--react-international-phone-background-color, white);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 60px !important;
  color: var(--react-international-phone-text-color, #222);
  font-family: inherit;
  font-size: var(--react-international-phone-font-size, 13px);
  /* width: 420px; */
  border-style: solid !important;
  border-color: #2f7f3d !important;
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 1px solid #2f7f3d !important;
  border-bottom: 1px solid #2f7f3d !important;
  background: #fff !important;
}

.attachfile {
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px dashed #999;
  gap: 32px;
  flex-shrink: 0;
  background-color: transparent;
  color: #999;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Light";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 100% */
}

.Submitt {
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px dashed #999;
  gap: 32px;
  flex-shrink: 0;
  background-color: #329244;
  color: white;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Light";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 100% */
}
.window {
  color: #329244;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins-Bold";
  font-size: 37px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* text-transform: capitalize; */
}

.investment {
  text-align: left;

  color: #073332;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins-Bold";
  font-size: 37px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.STAYcoNNECTED {
  color: #2f7f3d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footertextheading {
  color: #073332;
  font-family: "Crimson-Text";
  font-size: 17.719px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.6px; /* 121.905% */
}

.footertext {
  color: #618263;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Light";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.textfooterer {
  color: #073332;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.fade1 {
  transition: opacity 0.5s ease-in-out;
}

.visible1 {
  opacity: 1;
}

.hidden1 {
  opacity: 0;
  pointer-events: none;
  height: 0;
  overflow: hidden;
}

.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  -ms-transform: scale(1.08);
  transform: scale(1.5);
  z-index: 999;
  position: relative;
}
.center h3 {
  opacity: 0.8;
  transition: all 0.3s ease;
}
.cursor-pointer {
  /* width: 20%; */
}

/* ..................................................................................................... */

/* HeroSection.css */
.hero-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar {
  /* // Add your navbar styles here */
}

.image-container {
  position: relative;
  height: 70%;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-container {
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

.swiper {
  width: 100%;
  height: 100vh;
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* padding: 40px 60px; */
}

.swiper {
  width: 100%;
  height: 100vh;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  -ms-transform: scale(1.08);
  transform: scale(1.5);
  z-index: 999;
  position: relative;
}
.center h3 {
  opacity: 0.8;
  transition: all 0.3s ease;
}
.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  -ms-transform: scale(1.08);
  transform: scale(1.5);
  z-index: 999;
  position: relative;
  height: 460px;
}
.center h3 {
  opacity: 0.4;
  transition: all 0.3s ease;
  height: 418px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.center h3 img {
  height: 264px;
}

.center .slick-center h3 img {
  /* height: 100%; */
}

.textSuccess {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 72px;  */
}

.succesHeading {
  /* color: #fff; */
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 72px;  */
}

.whyInvestPakistan {
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.partnerBox {
  width: 230px;
  height: 110px;
}

.bgPictureSIFCVISA {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/Rectangle\ 3457\(3\).png");
}

.visaboxone {
  display: flex;
  /* width: 490px; */
  height: 620px;
  padding-bottom: 40px;
  flex-direction: column;
  align-items: center;
  /* gap: 43px; */
  border-radius: 8px;
  border: 1px solid rgba(47, 127, 61, 0.22);
  background: #fff;
  box-shadow: 0px 18px 88.7px -37px rgba(131, 206, 193, 0.38);
}

.textvisaheading1 {
  color: #2f7f3d;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 130.556%; /* 47px */
}

.butonvisa {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #329244;
  color: #2f7f3d;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Inter-Light";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 100% */
}

.textvisss {
  color: #073332;
  text-align: justify;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: left;
}

.headung2visa {
  color: #369e47;
  text-align: justify;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.heading3visa {
  color: #073332;
  text-align: justify;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.heading4 {
  color: #073332;
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.headingVISA {
  color: #2f7f3d;
  leading-trim: both;
  text-edge: cap;
  font-family: "Crimson-Text";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 130.556%; /* 47px */
  text-align: left;
}

.Howtoapply {
  color: #2f7f3d;

  font-family: "Crimson-Text";
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 130.556%; /* 62.667px */
}

.itText {
  color: #083534;
  -webkit-text-stroke: 2px #6aa69e;
  font-family: "Poppins";
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem; /* 28.125% */
  text-align: center;
  transition: color 0.5s ease-in;
}

.itStartUpsHeading {
  color: white;
  -webkit-text-stroke: 2px rgba(131, 206, 193, 0.61);
  font-family: "Poppins";
  font-size: 96px;
  font-style: normal;
  font-weight: 800;
  line-height: 110px; /* 114.583% */
  transition: color 0.5s ease-in;
}

.itStartUpsHeading:hover {
  color: #60a5fa;
}
.ITBGNumbering {
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
  background-image: url("../../assests/images/IT\ &\ Telecom\ banner\ Image.jpg");
}

.ITboxesHeading {
  display: flex;
  height: 128px;
  /* padding: 40px 10px 30px 10px; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid #1b5655;
  background: #073332;
  width: 250px;
  /* box-shadow: 0px 25px 72.8px 0px #001f1e; */
}
@media only screen and (max-width: 1339px) {
  .ITboxesHeading {
    width: 180px;
  }
}
@media only screen and (max-width: 767px) {
  .ITboxesHeading {
    width: 170px;
  }
}

.ITboxesHeading span {
  color: #fff;

  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media only screen and (max-width: 1339px) {
  .ITboxesHeading span {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .ITboxesHeading span {
    font-size: 20px;
  }
}

.ITboxesHeading:hover {
  box-shadow: 0px 25px 72.8px 0px #001f1e;
}

.itText:hover {
  color: #ffffff;
}
.bgPictureInsights {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/RectangleInsights.jpg");
}
.bgPictureTenders {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/Tenders\ New.jpg");
}
.bgAgriINNo {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/Agri&liveStockinno.png");
}
.bgPakLR {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/pakistanLR.png");
}
.bgIndusP {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/industryPotentiol.png");
}
.bgPakDev {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/pakdev.png");
}
.bgIgnite {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assests/images/ignite.png");
}

.dropdowntop {
  background: #fff;
  box-shadow: 0px 20px 70px 0px rgba(50, 146, 68, 0.15);
  border: 1px solid rgba(131, 206, 193, 0.47);
}

.dropdowntop222 {
  /* border-radius: 20px; */
  background: rgba(255, 255, 255, 0.76);
  -webkit-backdrop-filter: blur(1.5px);
  backdrop-filter: blur(5.5px);
}
.dropdownOpen {
  border-radius: 0px 0px 20px 20px;
  border-right: 1px solid rgba(131, 206, 193, 0.47);
  border-bottom: 1px solid rgba(131, 206, 193, 0.47);
  border-left: 1px solid rgba(131, 206, 193, 0.47);
  background: #fff;
  box-shadow: 0px 20px 70px 0px rgba(50, 146, 68, 0.15);
}

.dropdownOpen p {
  color: #6f7575;
  text-align: center;

  font-family: "Poppins";

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dropdownOpen p:hover {
  color: #073332;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* In your styles or Tailwind configuration file */
.vertical-text {
  writing-mode: vertical-lr;
  text-orientation: mixed;
  white-space: nowrap;
}

.topdropdown {
  /* border-radius: 20px; */
  background: rgba(255, 255, 255, 0.274);
  backdrop-filter: blur(1.5px);
}

.topdropdown222 {
  /* border-radius: 20px; */
  /* border-radius: 20px; */
  background: rgba(255, 255, 255, 0.76);
  -webkit-backdrop-filter: blur(1.5px);
  backdrop-filter: blur(5.5px);
}
.heading22222 {
  color: black;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 30px; 136.364% */
}

.heading222 {
  color: #ffff;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "Poppins";
  /* font-size: 22px; */
  font-style: normal;
  font-weight: 500;
  /* line-height: 30px; 136.364% */
}
.responsive-iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio (height/width) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  border: 0;
}
.slick-dots li.slick-active {
  background-color: #e67e22 !important;
}

.dotted-line {
  border-bottom: 1px dotted #b5b5b5;
  width: 100px; /* Adjust the width as needed */
  margin-top: 5px; /* Adjust the margin as needed */
  margin-bottom: 5px; /* Adjust the margin as needed */
}

.homepage
  .react-international-phone-input-container
  .react-international-phone-country-selector-button {
  background-color: white !important;
}
.homepage
  .react-international-phone-input-container
  .react-international-phone-input {
  background-color: white !important;
}
