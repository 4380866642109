.navbar1 {
  /* background-color: #1f5156; */
  background-color: transparent !important;
  position: fixed !important;
  backdrop-filter: blur(6.449999809265137px);
  /* height: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky !important;
  top: 0 !important;
  z-index: 20;
  opacity: 9999;
  box-shadow: 0px 10px 20px 0px rgba(131, 206, 193, 0.15);
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  max-width: 1500px;
  position: relative;
}

.nav-logo {
  color: #f5b921;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-items: center;
}

.nav-logo .icon {
  display: inline-block;
  width: 76px;
  height: 118px;
  margin-left: 16px;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  flex-direction: column;
  gap: 5px;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid navbar;
}
/* .fa-code {
  margin-left: 1rem;
} */

.nav-linksTop {
  color: #f08a23;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px; /* 112.5% */
}

.nav-item {
  /* line-height: 40px; */
  margin-right: 1rem;
  /* color: #fff; */

  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 100% */
}

.nav-item ul {
  display: flex;
  flex-direction: row;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

/* .nav-item:hover:after {
  width: 100%;
  padding: 0px 20px;
  background: #f08a23;
} */

.nav-item .active:active,
.nav-item .active:focus {
  /* color: #ffdd40; */
  /* border-radius: 21px;
background: #F08A23;  */
  border-bottom: 1px solid #f08a23;
}
.nav-link.active:active,
.nav-link.active:focus {
  /* color: #ffdd40; */
  /* border-radius: 21px;
background: #F08A23;  */
  border-bottom: 1px solid #f08a23;
}

.nav-item1 {
  /* line-height: 40px; */
  margin-right: 1rem;
}

.nav-item1 ul {
  /* display: flex; */
  flex-direction: row;
}

.nav-item1:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item1:hover:after {
  width: 100%;
  /* background: #ffdd40; */
}

.nav-item1 .active {
  /* color: #ffdd40; */
  /* border-bottom: 1px solid #white; */
}

.nav-icon {
  display: none;
  color: #f5b921;
}

.nav-icon .icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* border-top: 1pxsolid #fff; */
    position: absolute;
    top: 80px;
    right: -220%;
    opacity: 1;
    /* transition: all 0.1s ease; */
    margin-right: 0px;
  }

  .nav-menu.active {
    /* position: absolute; */
    background: #22574d;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    color: white !important;
    /* left: 0px; */
    opacity: 1;
    transition: all 0.5s ease;
    /* z-index: 1; */
    /* margin-top: 18px; */
    height: 100svh;
    top: 0px !important;
    bottom: 0px;
    width: 65%;
    right: 0px;
    font-size: 16px;
  }
  .nav-item .active {
    color: #ffdd40;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-logo .icon {
    width: 2rem;
    height: 2rem;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #ffdd40;
  }
}
@keyframes highlight {
  0% {
    color: #f5b921;
    background-color: transparent;
  }
  30% {
    color: Red;
    /* background-color: rgba(255, 255, 0, 0.2); */
  }
  80% {
    color: white;
    /* background-color: rgba(255, 255, 0, 0.2); */
  }
  100% {
    color: #f5b921;
    background-color: transparent;
  }
}
