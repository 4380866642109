/* YourComponent.css */

.slider-wrapper {
  position: relative;
}

.slick-center {
  opacity: 1 !important;
}

.havingtheOpacity {
  border-radius: 4px;
  background: linear-gradient(
    0deg,
    rgba(7, 51, 50, 0.6) 0%,
    rgba(7, 51, 50, 0.6) 100%
  );
  position: absolute;
  inset: 0;
  opacity: 0.6;
  z-index: 1;
}

.slick-prev,
.slick-next {
  font-size: 20px;
  z-index: 1;
  opacity: 60%;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}
/* YourComponent.css */
